import { Box, LinearProgress, Slide, Slider } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import NoSsr from '@material-ui/core/NoSsr';
import DirectionsIcon from '@material-ui/icons/Directions';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ListIcon from '@material-ui/icons/List';
import { GoogleMap, Marker, Polyline } from '@react-google-maps/api';
import Link, { navigate } from 'components/Link';
import Maps from 'components/Maps';
import markerBranch from 'images/markerBranch.png';
import markerEnd from 'images/trackingMarkers/end.png';
import markerBlueEmpty from 'images/trackingMarkers/marker-blue-empty.png';
import markerBlue from 'images/trackingMarkers/marker-blue.png';
import markerCyanEmpty from 'images/trackingMarkers/marker-cyan-empty.png';
import markerCyan from 'images/trackingMarkers/marker-cyan.png';
import markerGreenEmpty from 'images/trackingMarkers/marker-green-empty.png';
import markerGreen from 'images/trackingMarkers/marker-green.png';
import markerGreyEmpty from 'images/trackingMarkers/marker-grey-empty.png';
import markerGrey from 'images/trackingMarkers/marker-grey.png';
import markerRedEmpty from 'images/trackingMarkers/marker-red-empty.png';
import markerRed from 'images/trackingMarkers/marker-red.png';
import markerYellowEmpty from 'images/trackingMarkers/marker-yellow-empty.png';
import markerYellow from 'images/trackingMarkers/marker-yellow.png';
import markerStart from 'images/trackingMarkers/start.png';
import qs from 'query-string';
import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useGState } from 'state/store';
import { useCurrencyFormatter, useKeyPress } from 'utils/customHooks';
import fetch from 'utils/fetch';
import { getLatLng, mapStyles } from 'utils/mapsUtils';
import {
  formatDayTime,
  formatDistance,
  formatTime,
  formatTimeSpan,
  isVal,
  truncate,
} from 'utils/utils';

const convertDecToTime = (dec) => {
  let hour = Math.floor(dec);
  let decPart = dec - hour;
  let min = 1 / 60;
  decPart = min * Math.round(decPart * 60);
  let minute = Math.floor(decPart * 60) + '';
  if (minute.length < 2) minute = '0' + minute;
  var time = hour + ':' + minute;
  return time;
};

const getIconAssets = {
  visited: {
    withOffBeatSales: markerBlue,
    withoutOffBeatSales: markerGreen,
    withOffBeat: markerBlueEmpty,
    withoutOffBeat: markerGreenEmpty,
  },
  passed: {
    withOffBeatSales: markerCyan,
    withoutOffBeatSales: markerYellow,
    withOffBeat: markerCyanEmpty,
    withoutOffBeat: markerYellowEmpty,
  },
  notVisited: {
    withOffBeatSales: markerRed,
    withoutOffBeatSales: markerGrey,
    withOffBeat: markerRedEmpty,
    withoutOffBeat: markerGreyEmpty,
  },
};

const getIcon = (state, offBeat, salesValue) => {
  return iconUrl(state, offBeat, salesValue) || markerGrey;
};

const iconUrl = (state, offBeat, salesValue) => {
  let icon;
  if (state) {
    icon =
      state == 'not-visited'
        ? offBeat
          ? salesValue
            ? getIconAssets['notVisited'].withOffBeatSales
            : getIconAssets['notVisited'].withOffBeat
          : salesValue
          ? getIconAssets['notVisited'].withoutOffBeatSales
          : getIconAssets['notVisited'].withoutOffBeat
        : offBeat
        ? salesValue
          ? getIconAssets[state].withOffBeatSales
          : getIconAssets[state].withOffBeat
        : salesValue
        ? getIconAssets[state].withoutOffBeatSales
        : getIconAssets[state].withoutOffBeat;
  } else icon = markerGrey;

  return icon;
};

const SalesmanTracking = ({ salesmanId }) => {
  const { branchId, date } = qs.parse(location.search);
  const branch = useGState((s) => s.branches.find((b) => b.id == branchId));
  const goBack = () => {
    navigate(`/v3/salesman/${salesmanId}/retailers?date=${date}`);
  };

  return (
    <>
      <NoSsr>
        <Suspense fallback={<LinearProgress color="secondary" />}>
          <Box>
            <Maps
              render={() => (
                <TrackOnMap branch={branch} salesmanId={salesmanId} date={date} goBack={goBack} />
              )}
            />
          </Box>
        </Suspense>
      </NoSsr>
    </>
  );
};

const TrackOnMap = (props) => {
  const { salesmanId, branch, date } = props;
  const { data, isFetching } = useQuery(
    salesmanId !== 'undefined' && ['salesman_retailers_tracking', { salesmanId, date }],
    () => fetch(`/v3/salesman/${salesmanId}/retailers?date=${date}`)
  );

  return (
    <>
      {isFetching && <LinearProgress className="fixed top-16 w-screen z-50" color="secondary" />}
      <Link to={`/sales/Retailers/${salesmanId}`}>
        <IconButton className="fixed bottom-8 right-8 bg-white z-50">
          <ListIcon />
        </IconButton>
      </Link>
      {data ? <SalesTrack data={data} branch={branch} /> : null}
    </>
  );
};

const SalesTrack = (props) => {
  const { data, branch } = props;
  const {
    dashboard: { distanceTravelled, timeInMarket },
    retailers,
    softEvents = [],
  } = data;
  const mapRef = useRef();
  const shiftPressed = useKeyPress('Shift');
  const [mapTypeId, setMapTypeId] = useState('roadmap');
  const [zoom, setZoom] = useState(6);
  const [trackingPoints, setTrackingPoints] = useState([]);
  const [timeSelected, setTimeSelected] = useState(undefined);
  const [retailerSelected, setRetailerSelected] = useState({});
  const [showLegend, setShowLegend] = useState(false);

  const softEventsArray = useMemo(() => Object.values(softEvents), [softEvents]);
  const getTime = (d) => {
    const date = new Date(d);
    return date.getHours() + date.getMinutes() / 100;
  };

  const getMinMax = () => {
    const maxDate = new Date(softEventsArray[softEventsArray.length - 1].timestamp);
    const minDate = new Date(softEventsArray[0].timestamp);
    return {
      min: getTime(minDate),
      max: getTime(maxDate),
    };
  };
  useEffect(() => {
    if (softEventsArray) {
      const filteredTrackingPoints = softEventsArray.filter(
        (p) => timeSelected === undefined || getTime(p.timestamp) <= timeSelected
      );
      setTrackingPoints(filteredTrackingPoints);
    }
  }, [softEventsArray, timeSelected]);

  const retailersArray = useMemo(() => Object.values(retailers), [retailers]);
  const google = useMemo(() => window.google, []);
  const bounds = useMemo(() => {
    let bounds = new google.maps.LatLngBounds();

    retailersArray.forEach((r) => {
      if (r.latitude && r.longitude) bounds.extend(getLatLng(r));
    });

    if (branch?.locations?.[0])
      bounds.extend({ lat: branch.locations[0].latitude, lng: branch.locations[0].longitude });
    trackingPoints.forEach((point) => bounds.extend(getLatLng(point)));

    return bounds;
  }, [branch, google.maps.LatLngBounds, retailersArray, trackingPoints]);

  const renderRetailers = () => {
    const retailerMarkers = retailersArray.map((retailer) => {
      if (retailer.latitude && retailer.longitude) {
        return (
          <Marker
            key={retailer.id}
            position={{ lat: retailer.latitude, lng: retailer.longitude }}
            clickable
            icon={{
              url: getIcon(retailer?.status?.state, retailer.offBeat, retailer?.salesValue),
              scaledSize: new google.maps.Size(23, 33),
            }}
            onClick={(e) => setRetailerSelected({ ...retailer, ...e.pixel })}
          />
        );
      }
    });
    return retailerMarkers;
  };

  const renderPolyline = () => {
    let path = new google.maps.MVCArray();
    trackingPoints.forEach((event) => {
      path.push(new google.maps.LatLng(event.latitude, event.longitude));
    });

    return (
      <>
        <Polyline
          options={{
            strokeOpacity: 1,
            strokeWeight: 0.6,
          }}
          path={path}
        />
        {trackingPoints.length ? (
          <>
            <Marker
              position={{ lat: trackingPoints[0].latitude, lng: trackingPoints[0].longitude }}
              clickable
              icon={{ url: markerStart, scaledSize: new google.maps.Size(23, 33) }}
            />
            <Marker
              position={{
                lat: trackingPoints[trackingPoints.length - 1].latitude,
                lng: trackingPoints[trackingPoints.length - 1].longitude,
              }}
              clickable
              icon={{ url: markerEnd, scaledSize: new google.maps.Size(23, 33) }}
            />
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      <GoogleMap
        id="plan-polishing"
        zoom={zoom}
        mapContainerStyle={{
          height: '94vh',
          width: '100%',
        }}
        onLoad={(map) => {
          mapRef.current = map;
          map.fitBounds(bounds);
          map.mapTypes.set(
            'OSM',
            new google.maps.ImageMapType({
              getTileUrl: function (coord, zoom) {
                return (
                  'https://tile.openstreetmap.org/' + zoom + '/' + coord.x + '/' + coord.y + '.png'
                );
              },
              tileSize: new google.maps.Size(256, 256),
              name: 'OSM',
              maxZoom: 18,
            })
          );
        }}
        bounds={bounds}
        options={{
          styles: mapStyles,
          streetViewControl: false,
          draggable: shiftPressed ? false : true,
          scaleControl: false,
          mapTypeId,
          mapTypeControl: false,
          panControl: false,
          zoomControl: false,
          rotateControl: false,
          fullscreenControl: false,
        }}
        onMapTypeIdChanged={() => {
          if (mapRef.current) setMapTypeId(mapRef.current.mapTypeId);
        }}
      >
        <div className="absolute top-8 left-5 w-11/12">
          {trackingPoints.length ? (
            <SliderCard
              timeSelected={timeSelected}
              setTimeSelected={setTimeSelected}
              min={getMinMax().min}
              max={getMinMax().max}
            />
          ) : null}
        </div>
        <div className="absolute bottom-36 right-0 lg:bottom-20 lg:right-8">
          <ZoomControl setZoom={(val) => setZoom((zoom) => zoom + val)} />
          <MetricCard
            title="Travelled"
            value={`${distanceTravelled ? formatDistance(distanceTravelled.value) : 0} Kms`}
            src={require('images/road.png')}
          />
          <MetricCard
            title="Time Spent"
            value={timeInMarket?.value ? formatTime(timeInMarket.value / 1000) : formatTime(0)}
            src={require('images/time.png')}
          />
          {showLegend ? (
            <div
              class="bg-white w-max p-1 float-right mr-5 lg:mr-0 mt-3 rounded-lg cursor-pointer"
              onClick={() => setShowLegend(false)}
            >
              <Legend />
            </div>
          ) : (
            <div
              class="bg-white w-max p-1 float-right mt-3 mr-5 lg:mr-0 rounded-lg cursor-pointer"
              onClick={() => setShowLegend(true)}
            >
              <HelpOutlineIcon fontSize="large" />
            </div>
          )}
        </div>
        {branch?.locations?.[0] && (
          <Marker
            position={{ lat: branch.locations[0].latitude, lng: branch.locations[0].longitude }}
          />
        )}
        {renderRetailers()}
        {renderPolyline()}
        {Object.keys(retailerSelected).length != 0 && (
          <InfoWindowDialogSlide
            retailer={retailerSelected}
            setRetailerSelected={setRetailerSelected}
          />
        )}
      </GoogleMap>
    </>
  );
};

export default SalesmanTracking;

function InfoWindowDialogSlide(props) {
  const { retailer, setRetailerSelected } = props;
  const { code, name, status, offBeat, salesValue } = retailer;
  const formatCurrency = useCurrencyFormatter();
  let color = 'gray';
  if (status) {
    if (status.state === 'visited') color = 'green';
    else if (status.state === 'passed') color = 'yellow';
    else if (status.state === 'not-visited') color = 'red';
    if (offBeat) color = 'cyan';
  }
  const handleClose = () => {
    setRetailerSelected({});
  };

  return (
    <Slide direction="up" in={Boolean(retailer)} mountOnEnter unmountOnExit>
      <div
        style={{ top: '78vh' }}
        className={`flex flex-row relative mx-auto p-4 justify-start bg-white rounded-lg lg:w-1/3 md:w-1/2 xs:w-full`}
      >
        <div class={`bg-white rounded-lg p-3 shadow h-full border-l-8 border-${color}-500 w-full`}>
          <div class="flex justify-between items-center">
            <div class="flex">
              <p class="text-lg font-semibold mr-3">{truncate(code)}</p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.google.com/maps?daddr=${retailer.latitude},${retailer.longitude}`}
              >
                <DirectionsIcon color="primary" />
              </a>
            </div>

            <div class="flex">
              {isVal(status?.waitTime) ? (
                <p class="text-grey">{`${formatTimeSpan(status?.waitTime / 1000)}`}</p>
              ) : null}
              {isVal(status?.timestamp) ? (
                <p class="ml-2">{`${formatDayTime(new Date(status?.timestamp))}`}</p>
              ) : null}
            </div>
          </div>
          <div class="flex justify-between">
            <p class="text-sm">{name}</p>
            {isVal(salesValue) ? <p class="font-semibold">{formatCurrency(salesValue)}</p> : null}
          </div>
        </div>

        <button type="button" onClick={handleClose}>
          <img className="absolute top-3 right-3 w-3" src={require('images/cross.png')} />
        </button>
      </div>
    </Slide>
  );
}

function SliderCard(props) {
  const { timeSelected, setTimeSelected, min, max } = props;
  const handleChange = (event, newValue) => {
    setTimeSelected(newValue);
  };
  return (
    <div className="m-auto lg:m-0 lg:self-start flex flex-row items-center justify-center w-11/12 lg:w-1/3 bg-white rounded-lg px-2 pt-4 text-tertiary font-medium">
      <span className="mr-2">{convertDecToTime(min)}</span>
      <Slider
        value={timeSelected}
        onChange={handleChange}
        valueLabelDisplay="on"
        valueLabelFormat={convertDecToTime}
        step={0.01}
        min={min}
        defaultValue={min}
        max={max}
        classes={{ colorPrimary: 'text-light_blue' }}
      />
      <span className="ml-5">{convertDecToTime(max)}</span>
    </div>
  );
}
function MetricCard(props) {
  const { title, value, src } = props;
  return (
    <div className="mt-5 mr-5 lg:mr-0 shadow bg-white rounded-lg px-10 py-6 hidden md:block ">
      <div className="flex flex-col items-center">
        <img className="w-8 mb-3" src={src} />
        <p className="text-xl font-medium text-tertiary">{value}</p>
        <p className="font-medium text-sm text-inactive uppercase">{title}</p>
      </div>
    </div>
  );
}

function ZoomControl(props) {
  const { setZoom } = props;

  return (
    <div className="flex flex-row-reverse">
      <div className="flex flex-col shadow bg-white px-3 py-1 mr-5 text-3xl">
        <button className="border-solid border-b-2" onClick={() => setZoom(1)}>
          <p>+</p>
        </button>
        <button onClick={() => setZoom(-1)}>
          <p>-</p>
        </button>
      </div>
    </div>
  );
}

const markers = [
  { marker: markerGrey, text: 'Not visited-sales' },
  { marker: markerGreyEmpty, text: 'Not visited' },
  { marker: markerGreen, text: 'Visited-sales' },
  { marker: markerGreenEmpty, text: 'Visited' },
  { marker: markerYellow, text: 'Passed-sales' },
  { marker: markerYellowEmpty, text: 'Passed' },
  { marker: markerCyan, text: 'Passed-offbeat-sales' },
  { marker: markerRed, text: 'Not visited-offbeat-sales' },
  { marker: markerBlue, text: 'Visited-offbeat-sales' },
  { marker: markerBlueEmpty, text: 'Visited-offbeat' },
  { marker: markerStart, text: 'Current location' },
  { marker: markerEnd, text: 'Start location' },
  { marker: markerBranch, text: 'Branch location' },
];
function Legend() {
  return (
    <div>
      <p class="text-center font-semibold text-md my-2">Markers</p>
      {markers.map((marker) => (
        <div class="flex justify-between items-center px-2">
          <img src={marker.marker} height={20} width={20} alt="grey marker" />
          <p class="ml-2 mt-1">{marker.text}</p>
        </div>
      ))}
    </div>
  );
}
