import { Router } from '@reach/router';
import Layout from 'components/Layout';
import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import SalesmanTracking from 'sales/SalesmanTracking';

export default (props) => (
  <Router>
    <Component path="/sales/salesmanTracking/:salesmanId" layoutProps={props} />
  </Router>
);

const Component = ({ layoutProps, salesmanId }) => {
  return (
    <Layout {...layoutProps} hideNodeSelector>
      <ReactQueryConfigProvider
        config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
      >
        <SalesmanTracking salesmanId={salesmanId} />
      </ReactQueryConfigProvider>
    </Layout>
  );
};
